import React, { useContext } from "react"
import { Flex } from "ticeboxes"
import { NavContext } from "../../contexts/NavContext"
import useViewPort from "../../hooks/useViewport"
import Button, { ButtonLink } from "../general/Button"
import Logo from "../general/images/Logo"
import { P } from "../general/Typography"
import { navigate } from "@reach/router"
import useQueryParams from "../../hooks/useQueryParams"

interface Props {}

const Binnenkort: React.FC<Props> = () => {
  const { height: viewportHeight } = useViewPort()
  const { headerHeight } = useContext(NavContext)
  const params = useQueryParams()

  const height = viewportHeight - headerHeight

  return (
    <Flex
      py="5"
      mb="5"
      text-center
      style={{
        height,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Logo size="medium" />
      <P weight="light" className="mb-4">
        Deze pagina is binnenkort beschikbaar bij Eyefashion Leiden!
      </P>

      {params.get("goBack") ? (
        <Button onClick={() => navigate(-1)}>Ga terug</Button>
      ) : (
        <ButtonLink to="/">Terug naar de home pagina</ButtonLink>
      )}
    </Flex>
  )
}

export default Binnenkort
