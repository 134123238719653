import React from "react"
import Binnenkort from "../components/binnenkort/Binnenkort"
import Head from "../components/layout/Head"
import Layout from "../components/layout/Layout"

interface Props {}

const binnenkort: React.FC<Props> = () => {
  return (
    <>
      <Head
        title="Binnenkort"
        description="Deze pagina is binnenkort beschikbaar bij Eyefashion By Rami!"
      ></Head>
      <Layout>
        <Binnenkort />
      </Layout>
    </>
  )
}

export default binnenkort
